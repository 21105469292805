<template>
  <section
    class="section min-h-60 flex flex-col justify-center items-center bg-grey-lighter section privacy-page"
  >
  <h1 class="mb-12">Refund Policy</h1>
    <p>Last updated: August 08, 2022</p>
    <div class="max-w-lg font-light leading-lg">
  <p>
    Please read these terms and conditions carefully before using Our Service.
  </p>
  <p>No Refunds. Except as expressly provided herein, all payments under this Agreement will be irrevocable, non-refundable, and non-creditable.</p>
  <h1>Contact Us</h1>
  <p>
    If you have any questions about these Refund Policy, You can
    contact us:
  </p>
  <ul>
    <li>By email: <a :href="'mailto:'+$company.contact.email">{{$company.contact.email}}</a>.</li>
  </ul>
</div>

  </section>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
a {
  background-color: transparent;
}
*,
:after,
:before {
  box-sizing: inherit;
}
h1,
h2,
p {
  margin: 0;
}
*,
:after,
:before {
  border: 0 solid rgba(2, 25, 38, 0.07);
}
.bg-grey-lighter {
  background-color: rgba(2, 25, 38, 0.03);
}
.flex {
  display: flex;
}
.flex-col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.leading-lg {
  line-height: 1.7;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.max-w-lg {
  max-width: 50rem;
}
.min-h-60 {
  min-height: 60vh;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.text-lg {
  font-size: 1.125rem;
}
* {
  font-family: Poppins, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #0b2438;
  font-display: swap;
}
.h1,
h1 {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  font-size: 1.875rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 1.5px;
  text-align: center;
}
h1,
h2 {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}
body a:not(.navbar-link) {
  color: #ff6b6b;
}
body a:not(.navbar-link):active,
body a:not(.navbar-link):hover,
body a:not(.navbar-link):visited {
  color: #ff5064;
}
body a {
  text-decoration: none;
}
body p {
  line-height: 1.5;
  margin-bottom: 0.75rem;
}
body .stroke-text {
  background-repeat: no-repeat;
  background-size: 100% 95%;
  padding: 2px 12px 5px;
}
body .section {
  padding: 4rem 1.25rem;
}
@media (min-width: 768px) {
  body .section {
    padding: 6rem 4rem;
  }
}
@media (min-width: 992px) {
  body .section {
    padding: 8rem;
  }
}
/*! CSS Used from: Embedded */
div,
a,
p,
span {
  user-select: text !important;
}
p,
h1,
h2 {
  cursor: auto;
  user-select: text !important;
}
::selection {
  background-color: #338fff !important;
  color: #fff !important;
}
.privacy-page p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
</style>
